import React from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { withRouter } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { LINK_HOST } from 'config/app'
import { Input } from 'components/Form'
import { Popup } from 'components/Popup'
import { ReadableLinkAcc, handleViewChange, ReadableLinkCards } from './utils'
import { prettier } from 'pages/operations/Customers/json-prettifier'

import './styles.scss'

const ToggleViewButton = ({ isDeveloperView, handleClick }) => {
  return (
    <button onClick={handleClick} className="operationsButton">
      {isDeveloperView ? 'User View' : 'Developer View'}
    </button>
  )
}

// mergeType can be "vc" or "pulaoid"
const MergeLinkAccounts = ({ mergeType }) => {
  const [primary, setPrimary] = React.useState('')
  const [secondary, setSecondary] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [showPopup, setShowPopup] = React.useState(false)
  const [error, setError] = React.useState('')
  const [result, setResult] = React.useState(null)
  const [isFormOpen, setIsFormOpen] = React.useState(false)

  const handleMergeVc = () => {
    setIsLoading(true)
    const mergeVcApi = new API({
      url: `${LINK_HOST}/merge-vc/${primary}/${secondary}`,
    })

    mergeVcApi
      .post()
      .then((res) => {
        setResult(res)
      })
      .catch((err) => {
        setError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
        setShowPopup(true)
      })
  }

  const handleMergePulaoId = () => {
    setIsLoading(true)
    const mergePulaoIdApi = new API({
      url: `${LINK_HOST}/users/${primary}/merge/${secondary}`,
    })

    mergePulaoIdApi
      .post()
      .then((res) => {
        setResult(res)
      })
      .catch((err) => {
        setError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
        setShowPopup(true)
      })
  }

  return (
    <div>
      <div onClick={() => setIsFormOpen(!isFormOpen)}>
        <b>
          {mergeType === 'vc'
            ? getMessage('customerSupport.linkoperations.mergeVc.title')
            : getMessage(
                'customerSupport.linkoperations.mergePulaoId.title'
              )}{' '}
          {isFormOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
        </b>
      </div>
      {isFormOpen && (
        <div>
          {mergeType === 'pulaoid' && (
            <div className="dev-message">
              {getMessage(
                'customerSupport.linkoperations.mergePulaoId.devMessage'
              )}
            </div>
          )}
          <Input
            type="text"
            label={
              mergeType === 'vc'
                ? getMessage(
                    'customerSupport.linkoperations.mergePrimaryVc.inputlabel'
                  )
                : getMessage(
                    'customerSupport.linkoperations.mergePrimaryPulaoId.inputlabel'
                  )
            }
            placeholder={
              mergeType === 'vc'
                ? getMessage(
                    'customerSupport.linkoperations.mergePrimaryVc.placeholder'
                  )
                : getMessage(
                    'customerSupport.linkoperations.mergePrimaryPulaoId.placeholder'
                  )
            }
            name="primaryVc"
            value={primary}
            onChange={(e) => setPrimary(e)}
          />
          <Input
            type="text"
            label={
              mergeType === 'vc'
                ? getMessage(
                    'customerSupport.linkoperations.mergeSecondaryVc.inputlabel'
                  )
                : getMessage(
                    'customerSupport.linkoperations.mergeSecondaryPulaoId.inputlabel'
                  )
            }
            placeholder={
              mergeType === 'vc'
                ? getMessage(
                    'customerSupport.linkoperations.mergeSecondaryVc.placeholder'
                  )
                : getMessage(
                    'customerSupport.linkoperations.mergeSecondaryPulaoId.placeholder'
                  )
            }
            name="secondaryVc"
            value={secondary}
            onChange={(e) => setSecondary(e)}
          />
          <button
            onClick={mergeType === 'vc' ? handleMergeVc : handleMergePulaoId}
            className="operationsButton"
          >
            {isLoading
              ? '...'
              : getMessage('customerSupport.linkoperations.mergeVc.button')}
          </button>
          <Popup
            show={showPopup}
            heading={error ? 'Error' : 'Merge success'}
            close={() => {
              setShowPopup(false)
              setError('')
              setPrimary('')
              setSecondary('')
              setResult(null)
            }}
          >
            <div>
              {error ? (
                <div style={{ color: 'red' }}>{error}</div>
              ) : (
                <span className="successCheckmark">Success &#9989;</span>
              )}
            </div>
            {result && <pre>{JSON.stringify(result, null, 4)}</pre>}
          </Popup>
        </div>
      )}
    </div>
  )
}

const Patch = (props) => {
  const {
    title,
    label,
    placeholder,
    name,
    buttonText,
    pulaoId,
    refreshLinkAcc,
  } = props
  const [value, setValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [showPopup, setShowPopup] = React.useState(false)
  const [isFormOpen, setIsFormOpen] = React.useState(false)

  const patchLinkApi = new API({
    url: `${LINK_HOST}/users/${pulaoId}`,
  })

  const handleClickPatch = () => {
    let body = {}
    if (name === 'patchUid') {
      body = { uid: value }
    } else {
      body = { exchangeId: value }
    }
    setIsLoading(true)
    patchLinkApi
      .patch(body)
      .then(() => {
        setIsLoading(false)
        setShowPopup(true)
        refreshLinkAcc()
      })
      .catch((err) => {
        setError(err.message)
        setIsLoading(false)
        setShowPopup(true)
        refreshLinkAcc()
      })
  }

  return (
    <div>
      <div onClick={() => setIsFormOpen(!isFormOpen)}>
        <b>
          {title} {isFormOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
        </b>
      </div>
      {isFormOpen && (
        <div>
          <Input
            type="text"
            label={label}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(e) => setValue(e)}
          />
          <button
            onClick={handleClickPatch}
            className="operationsButton"
            disabled={value.length === 0}
          >
            {isLoading ? '...' : buttonText}
          </button>
          <Popup
            show={showPopup}
            heading={error ? 'Error' : 'Patch success'}
            close={() => {
              setShowPopup(false)
              setError('')
              setValue('')
            }}
          >
            <div>
              {error ? (
                <div style={{ color: 'red' }}>{error}</div>
              ) : (
                <span className="successCheckmark">Success &#9989;</span>
              )}
            </div>
          </Popup>
        </div>
      )}
    </div>
  )
}

const DissociateUid = ({ pulaoId, uid, refreshLinkAcc }) => {
  const [error, setError] = React.useState('')
  const [showPopup, setShowPopup] = React.useState(false)

  const dissociate = async () => {
    const dissociateApi = new API({
      url: `${LINK_HOST}/users/${pulaoId}/disconnect`,
    })

    dissociateApi
      .post()
      .then(() => {
        setShowPopup(true)
        refreshLinkAcc()
      })
      .catch((err) => {
        setError(err.message)
        setShowPopup(true)
      })
  }

  return (
    <div>
      <button onClick={dissociate} className="operationsButton" disabled={!uid}>
        Dissociate link account
      </button>
      <Popup
        show={showPopup}
        heading={error ? 'Error' : 'Dissociate success'}
        close={() => {
          setShowPopup(false)
          setError('')
        }}
      >
        <div>
          {error ? (
            <div style={{ color: 'red' }}>{error}</div>
          ) : (
            <span className="successCheckmark">Success &#9989;</span>
          )}
        </div>
      </Popup>
    </div>
  )
}

export const FullLinkAccView = (props) => {
  const { title, pulaoId } = props
  const [isFormOpen, setIsFormOpen] = React.useState(false)
  const [fullLinkAcc, setFullLinkAcc] = React.useState({})
  const getFullLinkAccApi = () => {
    const url = new API({
      url: `${LINK_HOST}/users/${pulaoId}?fields=identity,address,notification,associations`,
    })
    url
      .get()
      .then((res) => {
        const getFullAccFields = {
          identity: res.identity,
          address: res.address,
          notification: res.notification,
          associations: res.associations,
        }
        setFullLinkAcc(getFullAccFields)
      })
      .catch((err) => {
        const elem = document.getElementById('full-link-acc-error')
        elem.innerHTML = prettier.prettyPrint(err.message)
      })
  }

  const handleOpenForm = () => {
    const elem = document.getElementById('full-link-acc')
    if (!isFormOpen) {
      elem.innerHTML = prettier.prettyPrint(fullLinkAcc)
    } else {
      elem.innerHTML = ''
    }
    setIsFormOpen(!isFormOpen)
  }

  React.useEffect(() => {
    pulaoId && getFullLinkAccApi()
  }, [pulaoId])

  return (
    <div>
      <div onClick={handleOpenForm}>
        <b>
          {title} {isFormOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
        </b>
      </div>
      <div>
        {!fullLinkAcc.id && (
          <pre className="full-link-acc-error" id="full-link-acc-error"></pre>
        )}
        <pre className="full-link-acc" id="full-link-acc"></pre>
      </div>
    </div>
  )
}

const LinkOperations = (props) => {
  const virtualCard = props.router.match.params.vc
  const [toggleLinkAccDevView, setToggleLinkAccDevView] = React.useState(true)
  const [toggleLinkCardsDevView, setToggleLinkCardsDevView] =
    React.useState(true)
  const [linkAcc, setLinkAcc] = React.useState([])
  const [cards, setCards] = React.useState([])
  const getLinkAcc = async () => {
    const linkAccByVcApi = new API({
      url: `${LINK_HOST}/users/search`,
    })

    const apiCall = linkAccByVcApi
      .post({ vc: [virtualCard] })
      .then((res) => res)
      .catch((err) => err)
    const resovleResults = await Promise.resolve(apiCall)

    if (resovleResults.message) {
      const elem = document.getElementById('link-acc')
      elem.innerHTML = prettier.prettyPrint(resovleResults.message)
      setLinkAcc([])
    } else {
      const elem = document.getElementById('link-acc')
      elem.innerHTML = prettier.prettyPrint(resovleResults[0])
      setLinkAcc(resovleResults)
    }
  }

  const getCardList = async () => {
    const cardApi = new API({
      url: `${LINK_HOST}/cards?vc=${virtualCard}`,
    })
    cardApi
      .get()
      .then((res) => {
        const elem = document.getElementById('card-list')
        elem.innerHTML = prettier.prettyPrint(res.cards)
        setCards(res.cards)
      })
      .catch((err) => {
        const elem = document.getElementById('card-list-error')
        elem.innerHTML = prettier.prettyPrint(err.message)
      })
  }

  React.useEffect(() => {
    getLinkAcc()
    getCardList()
  }, [])

  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <h1>{getMessage('customerSupport.linkoperations.title')}</h1>
      <div className="opsParentWrapper">
        <div className="views">
          <div>
            <b>
              {getMessage(
                'customerSupport.linkoperations.lincAccDisplay.title'
              )}
            </b>
            <ToggleViewButton
              isDeveloperView={toggleLinkAccDevView}
              handleClick={() =>
                handleViewChange(
                  linkAcc[0],
                  toggleLinkAccDevView,
                  setToggleLinkAccDevView,
                  'link-acc'
                )
              }
            />
          </div>
          {!toggleLinkAccDevView && <ReadableLinkAcc linkAcc={linkAcc[0]} />}
          {<pre className="link-acc" id="link-acc"></pre>}
          <hr />
          <div>
            <b>
              {getMessage(
                'customerSupport.linkoperations.lincCardDisplay.title'
              )}
            </b>
            <ToggleViewButton
              isDeveloperView={toggleLinkCardsDevView}
              handleClick={() =>
                handleViewChange(
                  cards,
                  toggleLinkCardsDevView,
                  setToggleLinkCardsDevView,
                  'card-list'
                )
              }
            />
          </div>
          {<pre className="card-list" id="card-list"></pre>}
          {<pre className="card-list-error" id="card-list-error"></pre>}
          {!toggleLinkCardsDevView && <ReadableLinkCards cards={cards} />}
        </div>
        <div className="operations">
          <DissociateUid
            pulaoId={linkAcc[0]?.id}
            uid={linkAcc[0]?.uid}
            refreshLinkAcc={getLinkAcc}
          />
          <hr />
          <MergeLinkAccounts mergeType={'vc'} />
          <hr />
          <MergeLinkAccounts mergeType={'pulaoid'} />
          <hr />
          <Patch
            title={getMessage('customerSupport.linkoperations.patchUid.title')}
            label={getMessage(
              'customerSupport.linkoperations.patchUid.inputlabel'
            )}
            placeholder={getMessage(
              'customerSupport.linkoperations.patchUid.placeholder'
            )}
            name={'patchUid'}
            buttonText={getMessage(
              'customerSupport.linkoperations.patchUid.button'
            )}
            pulaoId={linkAcc[0]?.id}
            refreshLinkAcc={getLinkAcc}
          />
          <hr />
          <Patch
            title={getMessage(
              'customerSupport.linkoperations.exchangeId.title'
            )}
            label={getMessage(
              'customerSupport.linkoperations.exchangeId.inputlabel'
            )}
            placeholder={getMessage(
              'customerSupport.linkoperations.exchangeId.placeholder'
            )}
            name={'patchExchangeId'}
            buttonText={getMessage(
              'customerSupport.linkoperations.exchangeId.button'
            )}
            pulaoId={linkAcc[0]?.id}
            refreshLinkAcc={getLinkAcc}
          />
          <hr />
          <FullLinkAccView
            title={getMessage(
              'customerSupport.linkoperations.fullLinkAccount.title'
            )}
            pulaoId={linkAcc[0]?.id}
          />
        </div>
      </div>
    </AuthenticatedPage>
  )
}

export default withRouter(({ location, history, match, ...props }) => (
  <LinkOperations router={{ location, history, match }} {...props} />
))
