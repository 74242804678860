import React from 'react'
import { prettier } from 'pages/operations/Customers/json-prettifier'
import { format } from 'date-fns'

export const getCardType = (cardType) => {
  const cardBinMap = {
    810500: 'Link card (formerly Plus! card)',
    810501: 'Link card (formerly Plus! card)',
    810800: 'NTUC card',
    820100: 'OCBC Plus! Visa Credit/Debit card',
    830100: 'OCBC NTUC Visa Credit/Debit card',
    810300: 'nEbO card',
    810503: 'NTUC Social Membership card',
    830200: 'NTUC Link Credit/Debit card',
    820200: 'Trust Link Credit/Debit card',
    810908: 'Virtual card',
  }
  return cardBinMap[cardType] ? cardBinMap[cardType] : 'Link card'
}

export const ReadableLinkAcc = ({ linkAcc }) => {
  return (
    <div className="user-view">
      <div>
        <b>VC</b>:&nbsp;&nbsp;&nbsp;{linkAcc.vc}
      </div>
      <div>
        <b>Exchange Id</b>:&nbsp;&nbsp;&nbsp;{linkAcc.exchangeId || 'none'}
      </div>
      <div>
        <b>Uid</b>:&nbsp;&nbsp;&nbsp;{linkAcc.uid || 'none'}
      </div>
      <div>
        <b>Name</b>:&nbsp;&nbsp;&nbsp;{linkAcc.salutation} {linkAcc.firstName}{' '}
        {linkAcc.lastName}
      </div>
      <div>
        <b>Birthday</b>:&nbsp;&nbsp;&nbsp;
        {linkAcc.dateOfBirth
          ? format(linkAcc.dateOfBirth, 'dd/MM/yyyy')
          : 'null'}
      </div>
      <div>
        <b>Phone number</b>:&nbsp;&nbsp;&nbsp;{linkAcc.countryCode && '+'}
        {linkAcc.countryCode} {linkAcc.primaryPhoneNumber}
      </div>
      <div>
        <b>Email</b>:&nbsp;&nbsp;&nbsp;{linkAcc.email || 'none'}
      </div>
      <div>
        <b>Gender</b>:&nbsp;&nbsp;&nbsp;{linkAcc.gender || 'none'}
      </div>
      <div>
        <b>Nationality</b>:&nbsp;&nbsp;&nbsp;{linkAcc.nationality || 'none'}
      </div>
      <div>
        <b>Is Account Active</b>:&nbsp;&nbsp;&nbsp;{linkAcc.active.toString()}
      </div>
    </div>
  )
}

const parseCardDate = (date) => {
  const parseDate = date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
  return format(parseDate, 'dd/MM/yyyy')
}

export const ReadableLinkCards = ({ cards }) => {
  return (
    <div className="user-view">
      {cards.map((card) => {
        return (
          <div key={card.cardNumber} className="user-view-child">
            <div>
              <b>Card Number</b>: {card.cardNumber}
            </div>
            <div>
              <b>Name on card</b>: {card.nameOnCard}
            </div>
            <div>
              <b>Card type</b>: {getCardType(card.cardType)}
            </div>
            <div>
              <b>Card Issue date</b>: {parseCardDate(card.cardIssueDate)}
            </div>
            <div>
              <b>Termination date</b>: {parseCardDate(card.terminationDate)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const handleViewChange = (viewObj, devViewState, setView, targetPre) => {
  setView(!devViewState)
  const elem = document.getElementById(targetPre)

  if (!devViewState) {
    elem.innerHTML = prettier.prettyPrint(viewObj)
  } else {
    elem.innerHTML = ''
  }
}
